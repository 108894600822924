import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { fetchBadgeVault } from '../services/api';
import { FaTwitter, FaFacebook, FaShare } from 'react-icons/fa';
import '../styles/BadgeVaultPage.css';

function BadgeVaultPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [userHash, setUserHash] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlUserHash = params.get('userHash');
    
    if (urlUserHash) {
      localStorage.setItem('userHash', urlUserHash);
      setUserHash(urlUserHash);
    } else {
      const storedHash = localStorage.getItem('userHash');
      setUserHash(storedHash);
    }
    
    if (!urlUserHash && !localStorage.getItem('userHash')) {
      setError({
        title: 'User Not Found',
        message: 'Please check your email for the correct badge vault link or sign up to start earning badges!',
        actionText: 'Sign Up Now',
        actionLink: '/'
      });
      return;
    }
  }, [location, navigate]);

  useEffect(() => {
    const loadBadgeVault = async () => {
      if (!userHash) {
        setError('No badge vault found here');
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const responseData = await fetchBadgeVault(userHash);
        setData(responseData);
        setError(null);
      } catch (err) {
        setError(err.response?.data?.error || 'Failed to load badge vault');
      } finally {
        setLoading(false);
      }
    };

    if (userHash) {
      loadBadgeVault();
    }
  }, [userHash]);

  const handleShare = async (platform, badge = null) => {
    const baseUrl = 'https://www.momentumchallenges.com';
    const badgeVaultUrl = `${baseUrl}/badge-vault?userHash=${encodeURIComponent(userHash)}`;
    
    let text;
    if (badge) {
      text = `Check out the badge I earned on Momentum! 🏅`;
    } else {
      const totalBadges = data.stats.goldTotal + data.stats.silverTotal + data.stats.bronzeTotal;
      const currentStreak = data.stats.currentStreak;
      text = `Check out my badge vault at Momentum! I've earned ${totalBadges} badges! My current streak is ${currentStreak}! 🏆`;
    }

    let shareUrl;
    switch (platform) {
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${badge ? badgeVaultUrl : badgeVaultUrl}`;
        break;
      case 'facebook':
        shareUrl = `https://www.facebook.com/dialog/share?app_id=1107643947701312&display=popup&href=${badgeVaultUrl}&quote=${encodeURIComponent(text)}&hashtag=%23MomentumChallenges`;
        break;
      case 'general':
        if (navigator.share) {
          try {
            await navigator.share({
              title: 'Momentum - Badge Vault',
              text: text,
              url: badgeVaultUrl
            });
          } catch (err) {
            if (err.name !== 'AbortError') {
              await navigator.clipboard.writeText(`${text}\n${badgeVaultUrl}`);
              alert('Share text copied to clipboard!');
            }
          }
          return;
        } else {
          await navigator.clipboard.writeText(`${text}\n${badgeVaultUrl}`);
          alert('Share text copied to clipboard!');
          return;
        }
      default:
        return;
    }

    window.open(shareUrl, '_blank', 'width=600,height=400');
  };

  if (loading) {
    return (
      <div className="badge-vault-container">
        <div className="loading-state">
          <div className="loading-spinner"></div>
          <p>Loading your achievements...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="badge-vault-container">
        <div className="error-state">
          <h2>{error.title || 'Oops! Something went wrong'}</h2>
          <p>{error.message || error}</p>
          {error.actionLink ? (
            <Link to={error.actionLink} className="primary-button">
              {error.actionText}
            </Link>
          ) : (
            <button onClick={() => window.location.reload()} className="primary-button">
              Try Again
            </button>
          )}
        </div>
      </div>
    );
  }

  if (!data || !data.stats) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="text-center p-8">
          <h2 className="text-xl text-gray-400">No badges found</h2>
        </div>
      </div>
    );
  }

  return (
    <div className="badge-vault-container">
      <div className="animated-background">
        <div className="gradient-overlay"></div>
      </div>

      <div className="badge-vault-content">
        <div className="vault-header">
          <h1 className="vault-title">Badge Vault</h1>
          <h4 className="vault-subtitle">Showcasing Momentum</h4>

          <div className="share-buttons">
            <button onClick={() => handleShare('twitter')} className="share-button twitter">
              <FaTwitter /> Share My Badge Vault
            </button>
            <button onClick={() => handleShare('facebook')} className="share-button facebook">
              <FaFacebook /> Share My Badge Vault
            </button>
            <button onClick={() => handleShare('general')} className="share-button share">
              <FaShare /> Share My Badge Vault
            </button>
          </div>

          <a href="/" className="join-momentum-button">
            Next Challenge Starting Soon!
          </a>
        </div>

        <div className="stats-grid">
          <div className="stat-item">
            <div className="stat-value">🔥 {data?.stats?.currentStreak || 0}</div>
            <div className="stat-label">Current Streak</div>
          </div>
          <div className="stat-item">
            <div className="stat-value">🥇 {data?.stats?.goldTotal || 0}</div>
            <div className="stat-label">Gold</div>
          </div>
          <div className="stat-item">
            <div className="stat-value">🥈 {data?.stats?.silverTotal || 0}</div>
            <div className="stat-label">Silver</div>
          </div>
          <div className="stat-item">
            <div className="stat-value">🥉 {data?.stats?.bronzeTotal || 0}</div>
            <div className="stat-label">Bronze</div>
          </div>
        </div>

        {data?.completions && data.completions.length > 0 ? (
          <div className="badges-grid">
            {data.completions
              .sort((a, b) => new Date(b.completedAt) - new Date(a.completedAt))
              .map((completion) => (
                <div 
                  key={completion.id} 
                  className="badge-card"
                  data-difficulty={completion.difficulty.toLowerCase()}
                >
                  <div className="badge-content">
                    <img 
                      src={completion.badge?.imageUrl} 
                      alt={`${completion.challenge?.title} Badge`}
                      className="badge-image"
                    />
                    <h3 className="badge-title">{completion.challenge?.title}</h3>
                    <p className="badge-date">
                      {new Date(completion.completedAt).toLocaleDateString()}
                    </p>
                    <div className={`badge-difficulty ${completion.difficulty}`}>
                      {completion.difficulty.toUpperCase()}
                    </div>
                  </div>

                  <div className="badge-share">
                    <button 
                      onClick={() => handleShare('twitter', completion)} 
                      className="badge-share-button"
                    >
                      <FaShare /> Share Badge
                    </button>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className="no-badges">
            <center>
              <p>
                It looks like you haven't earned any badges yet, but don't worry—your fitness journey has just begun. 
                Participate in our exciting challenges to unlock badges that celebrate your progress and achievements. Each 
                badge is a milestone showcasing your dedication and hard work.
              </p>
            </center>
          </div>
        )}
      </div>
    </div>
  );
}

export default BadgeVaultPage; 














